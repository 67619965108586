import React from "react"
import Loadable from "react-loadable"

const GalerieLoadable = Loadable({
  loader: () => import("./galerie"),
  loading() {
    return <div></div>
  },
})

export default props => {
  return (
    <div>
      <GalerieLoadable tab={props} />
    </div>
  )
}
